<template>
  <v-container fluid>
    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <!-- :sort-by="sortBy.toLowerCase()" -->
      <!-- <template v-slot:header>
        <v-toolbar dark color="blue darken-3" class="mb-1">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-select
              v-model="sortBy"
              flat
              solo-inverted
              hide-details
              :items="keys"
              prepend-inner-icon="mdi-magnify"
              label="Sort by"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="sortDesc" mandatory>
              <v-btn large depressed color="blue" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="blue" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template> -->

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{ item.name }}
                <v-btn
                  :href="item.link"
                  v-if="item.link"
                  x-small
                  target="_blank"
                  text
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <span class="caption font-weight-light"
                >Role : {{ item.role }}</span
              >
              <v-chip-group active-class="primary--text " column>
                <v-chip outlined v-for="tag in item.techStack" x-small :key="tag" class="mr-2 ml-2">
                  {{ tag }}
                </v-chip>
              </v-chip-group>
              <!-- <v-list dense>
                <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                  <v-list-item-content
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ key }}:
                  </v-list-item-content>
                  <v-list-item-content
                    class="align-end"
                    :class="{ 'blue--text': sortBy === key }"
                  >
                    {{ item[key.toLowerCase()] }}
                  </v-list-item-content>
                </v-list-item>
              </v-list> -->
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <!-- <span class="grey--text">Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->

          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            Page {{ page }} of {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
            color="primary "
            class="mr-1"
            x-small
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            color="primary"
            class="ml-1"
            x-small
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
export default {
  name: "Projects",
  data() {
    return {
      itemsPerPageArray: [4, 8, 12],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: "name",
      keys: ["Name", "Tech stack", "Role", "Responsibility", "Link"],
      items: [
        {
          name: "Cabinet.cloud",
          techStack: [
            "VueJS",
            "NodeJS",
            "AWS Serverless architecture",
            "AWS AppSync",
            "AWS Cognito User Pool",
            "AWS DynamoDB",
            "AWS Lambda fn",
            "AWS S3",
            "AWS SQS",
            "AWS CloudWatch",
          ],
          role: "Project manager",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
          link: "https://www.thecabinet.cloud/",
        },
        {
          name: "WRKTOP",
          techStack: [
            "VueJS",
            "NodeJS",
            "AWS Serverless architecture",
            "AWS AppSync",
            "AWS Cognito User Pool",
            "AWS DynamoDB",
            "AWS Lambda fn",
            "AWS API Gateway",
            "AWS SQS",
            "AWS CloudWatch",
            "Ejabberd",
            "AWS Textract",
            "Websockets",
          ],
          role: "Software developer / Project manager",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
          link: "http://www.wrktop.com/",
        },
        {
          name: "AdjusterMate",
          techStack: [
            "VueJS",
            "AWS Cognito User Pool",
            "PHP",
            "PostgresSQL",
            "AWS API Gateway",
            "AWS SES",
          ],
          role: "Software developer / Project manager",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
          link: "https://adjustermate.simplesolve.com",
        },
        {
          name: "Content Delivery Portal",
          techStack: [
            "VueJS",
            "NodeJS",
            "AWS Serverless architecture",
            "AWS AppSync",
            "AWS Cognito User Pool",
            "AWS DynamoDB",
            "AWS Lambda fn",
            "AWS API Gateway",
            "AWS SQS",
            "AWS CloudWatch",
            "AWS S3",
            "AWS CloudFront",
          ],
          role: "Software developer / Project manager",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
        },
        {
          name: "TRPEZE",
          techStack: [
            "VueJS",
            "NodeJS",
            "AWS Serverless architecture",
            "AWS AppSync",
            "AWS Cognito User Pool",
            "AWS DynamoDB",
            "AWS Lambda fn",
            "AWS API Gateway",
            "AWS SQS",
            "AWS CloudWatch",
          ],
          role: "Software developer / Project manager",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
          link: "http://trpze.com/",
        },
        {
          name: "Incidento",
          techStack: [
            "VueJS",
            "NodeJS",
            "AWS Serverless architecture",
            "AWS AppSync",
            "AWS Cognito User Pool",
            "AWS DynamoDB",
            "AWS Lambda fn",
            "AWS API Gateway",
            "AWS CloudWatch",
          ],
          role: "Software developer / Project manager",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
          link: "— https://incidento.app",
        },
        {
          name: "GatePass",
          techStack: [
            "VueJS",
            "NodeJS",
            "AWS Serverless architecture",
            "PostgresSQL",
            "AWS AppSync",
            "AWS Cognito User Pool",
            "AWS DynamoDB",
            "AWS Lambda fn",
            "AWS API Gateway",
            "AWS SQS",
            "AWS CloudWatch",
          ],
          role: "Software developer / Project manager",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
          link: "https://www.gatepass.app/",
        },
        {
          name: "Skilter",
          techStack: [
            "VueJS",
            "AWS Serverless architecture",
            "AWS AppSync",
            "AWS Cognito User Pool",
            "AWS DynamoDB",
            "AWS Lambda fn",
            "AWS API Gateway",
            "AWS CloudWatch",
          ],
          role: "Software developer",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
          link: "https://www.skilter.app/",
        },
        {
          name: "Canon Configurator",
          techStack: [
            "JavaScript ES6",
            "jQuery",
            "NodeJS",
            "AWS DynamoDB",
            "AWS Lambda fn",
            "AWS API Gateway",
          ],
          role: "Full stack developer",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
        },
        {
          name: "LimeKast",
          techStack: [
            "JavaScript ES6",
            "jQuery",
            "PHP",
            "PostgresSQL",
            "Gearman",
            "Ejabberd",
            "AWS EC2",
          ],
          role: "Full stack developer",
          responsibility:
            "Leading the team along with customer point of contact and full-stack development on VueJS (Quasar framework) and AWS Serverless Architecture.",
          link: "http://www.wrktop.com/",
        },
      ],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>
